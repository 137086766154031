<template>
    <div class="pwdChange">
      <van-cell>
        <div class="phone">+86 {{mobile.substr(0, 3)}} {{mobile.substr(3, 4)}} {{mobile.substr(7, 4)}} </div>
      </van-cell>
      <van-form validate-first>
        <van-field  :rules="[{ required: true, message: '请输入验证码' }]" v-model="dataForm.code" placeholder="请输入验证码" >
          <template #button>
            <van-button size="small"  @click="getCode" ><p :style="isCode ? 'color:#256EF8' : 'color: #ccc'">发送验证码{{isCode ? '' : num}}</p></van-button>
          </template>
        </van-field>
          <van-field :rules="[{ required: true,  message: '密码必须包含字母、数字和特殊符号,长度为8-16位', validator: CheckPwd }]" v-model="dataForm.newPwd1" placeholder="请输入8-16位包含字母、数字和特殊符号新密码" type="text" />
          <van-field :rules="[{ required: true, message:'两次密码不一致', validator: CheckPwd2 }]" v-model="dataForm.newPwd2" placeholder="再次输入密码" type="text" />
        <p class="tip">8-16位密码，同时包含字母、数字和特殊符号</p>
        <div style="position: relative">
          <van-button class="btns" round type="info" @click="changePwd">确认修改</van-button>
        </div>
      </van-form>
    </div>
</template>
<script>
import {CheckPassWord} from '@/utils/validate'

export default {
    data () {
        return {
            dataForm: {
                oldPwd: '',
                newPwd1: '',
                newPwd2: '',
              code: ''
            },
          num: 30,
          isCode: true,
          timer: null,
          mobile: ''
        }
    },
  created() {
    this.mobile = this.$route.query.mobile || this.$globalData.userInfo.mobile
  },
  methods: {
    CheckPwd2 () {
      return  this.dataForm.newPwd1 == this.dataForm.newPwd2

    },
    CheckPwd () {
      return CheckPassWord(this.dataForm.newPwd1)
    },
    getCode () {
      if (this.isCode) {
        this.isCode = false
        this.$http({
          url: 'https://api.hzuht.com/general/verify/sendVerifyCode',
          method: 'post',
          params: this.$http.adornParams({
            mobile: this.mobile
          },false)
        }).then(({data}) => {
          if (data.code == 0) {
            this.$toast.success('发送成功')
          } else {
            this.$toast.fail(data.msg)
          }
        })
        this.timer = setInterval(() => {
          this.num--
          if (this.num === 0) {
            this.isCode = true
            clearInterval(this.timer)
            this.num = 30
          }
        }, 1000)
      }
    },
    changePwd () {
      if  (this.dataForm.newPwd1 != this.dataForm.newPwd2) {
            return this.$toast.fail('两次输入的密码不一致')
      }
      this.$http({
          url: this.$http.adornUrl('/wxapp/user/updatePassworddd'),
          method: 'post',
          params: this.$http.adornParams({
              newPassword: this.dataForm.newPwd2,
              code: this.dataForm.code
          })
      }).then(({data})=> {
          if (data.code == 0) {
            this.$toast.success ({
              message: '修改成功，请重新登录',
              duration: 3000,
              onClose: () =>{
                this.$http({
                  url: this.$http.adornUrl('/wxapp/unlink'),
                  method: 'post',
                  params: this.$http.adornParams({
                    id: parseInt(this.$globalData.userInfo.userId)
                  })
                }).then(({data})=> {
                  if (data.code == 0) {
                    this.$globalData.key = data.key
                    this.$router.replace('/login')
                  } else {
                    this.$toast.fail(data.msg)
                  }
                })
              }
            })
          } else {
              this.$toast.fail(data.msg)
          }
      })
    }
  }
}
</script>
<style scoped>

.pwdChange >>> .van-field__control {
    text-align: left !important;
}
.pwdChange >>> .van-button--default {
  border: none !important;
}
.phone {
  font-weight: bold;
  line-height: 60px;
  padding: 0 25px;
  font-size: 30px;
  height: 60px;
}
.tip {
  font-size: 24px;
  color: #999;
  margin-left: 32px;
}
.btn {
    width: 600px;
    position: absolute;
    left: 50%;
    top: 360px;
    transform: translateX(-50%);
}
</style>
